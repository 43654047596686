import * as React from "react"
import { Link, graphql } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Seo from '../components/seo'
import Top from '../components/top'
import Footer from '../components/footer'
import Header from "../components/header"
import Contact from '../components/contact'

const BlogPostPage = ({data}) => {
  const entry = data.prismicBlog
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Top />
      <Header />
      <div className="w-full py-16 relative">
        {entry.data.banner.gatsbyImageData === null ? <div className="absolute -inset-0">
              <div className="w-full h-full bg-grey-e opacity-100"></div>
            </div>
        : <>
            <div className="absolute -inset-0">
              <GatsbyImage className="w-full h-full object-cover block" image={entry.data.banner.gatsbyImageData} alt={entry.data.banner.alt ?? ''} />
            </div>
            <div className="absolute -inset-0">
              <div className="w-full h-full bg-black opacity-50"></div>
            </div>
          </>
        }
        <div className="relative">
          <div className="w-10/12 mx-auto max-w-screen-xl">
            <div className="flex justify-between gap-16">
              <div className="w-8/12">
                <div className="text-4xl sm:text-5xl text-white">
                  <h1><span className="text-white mr-3">|</span>{entry.data.heading ?? ''}</h1>
                </div>
              </div>
              <div className="w-4/12">
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full py-4">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex items-center justify-end gap-3">
            <svg xmlns="http://www.w3.org/2000/svg" className="inline h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>
            <Link to="/">Home</Link>
            /
            <Link to="/news/">News</Link>
          </div>
        </div>
      </div>
      <div className="w-full py-16 bg-white relative border-t border-grey-c">
        <div className="w-10/12 mx-auto max-w-screen-xl relative">
          <div className="flex-none sm:flex justify-between items-start gap-32">
            <div className="w-full sm:w-8/12">
              <figure className="mb-12">
                <GatsbyImage className="aspect-w-1 aspect-h-1" style={{ width: "100%", height: "100%", display: "block", objectFit: 'cover' }} image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ? entry.data.image.alt : ''} />
              </figure>
              <div className="prose prose-lg prose-h2:font-normal">
                <PrismicRichText field={entry.data.text.richText} />
              </div>
            </div>
            <div className="w-full sm:w-4/12">
              <figure>
                &nbsp;
              </figure>
            </div>
          </div>
        </div>
      </div>
      <Contact />
      <Footer />
    </>
  )
}

export const query = graphql`
query($handle: String) {
  prismicBlog(uid: {eq: $handle}) {
    data {
      meta_description
      page_title
      heading
      text {
        richText
      }
      banner {
        gatsbyImageData
        alt
      }
      image {
        gatsbyImageData
        alt
      }
    }
  }
}
`

export default BlogPostPage
